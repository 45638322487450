<template>
<v-container>
  <v-row>
    <v-col>
      <p class="text-h2">You have been Logged Out!!!!</p>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import {LOGOUT} from "@/store/actions.type";

export default {
  name: "LogoutPage",
  created() {
    this.$store.dispatch(`auth/${LOGOUT}`);
  }
}
</script>

<style scoped>

</style>